.cardwidgets_head{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    text-transform: capitalize;
    padding: .8rem 0;
}

.cardwidgets__counter{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.cardwidgets__counter span{
}